import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"

const Course = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "course-image-2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      ico1: file(relativePath: { eq: "service_ico1.png" }) {
        childImageSharp {
          fixed(width: 74, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Course" />
      <BackgroundImage
        Tag="div"
        className="bodhi__section-image-header"
        fluid={data.mainImage.childImageSharp.fluid}
      >
        <h1>Courses</h1>
      </BackgroundImage>
      <div
        id="service_2"
        className="course__services bodhi__bg-pattern margin-header"
      >
        <div className="container">
          <div className="row perk">
            <div className="col-sm-12">
              <p>
                Bodhi currently has NZQA approval for two programmes, the New
                Zealand Diploma in Early Childhood Education and Care, Level 5 &
                6. Click here to find out what each means and how to enrol.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Course
